import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";

// Import images
import myppimage from "../assets/img/my_image144.webp";
import littleStarSchool from "../assets/img/little-star-english-school.webp";
import technicalEducationCollege from "../assets/img/technical-education-college.webp";
import rkuniversity from "../assets/img/rkuniversity.webp";
import reading from "../assets/img/reading.png";
import running from "../assets/img/running.png";
import cooking from "../assets/img/cooking.png";
import movies from "../assets/img/movies.png";
import netflix from "../assets/img/netflix.png";
import coding from "../assets/img/coding.jpg";
import traveling from "../assets/img/traveling.jpg";
import Footer from "../components/Footer";

// Preload images function
const preloadImages = (imageArray) => {
  return Promise.all(
    imageArray.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = resolve; // Resolve on error to avoid blocking
      });
    })
  );
};

const AboutPage = ({ darkMode }) => {
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    document.title = "About Me | Bhishan Sah - Software Engineer";

    // Set meta description
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = "Here is my document certificate of my education";
    document.head.appendChild(metaDescription);

    // Preload images
    const loadImages = async () => {
      await preloadImages([
        myppimage,
        littleStarSchool,
        technicalEducationCollege,
        rkuniversity,
        reading,
        running,
        cooking,
        movies,
        netflix,
        coding,
        traveling,
      ]);
      setLoading(false); // Set loading to false after images are loaded
    };

    loadImages();

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const AnimatedSection = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false });

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    );
  };

  // Loading sketch placeholder
  const LoadingSketch = () => (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      <div className="animate-pulse h-72 w-full rounded-lg bg-gray-300" />
      <div className="w-1/2 h-6 bg-gray-300 animate-pulse rounded" />
      <div className="w-3/4 h-4 bg-gray-300 animate-pulse rounded" />
      <div className="w-2/3 h-4 bg-gray-300 animate-pulse rounded" />
      <div className="w-1/4 h-4 bg-gray-300 animate-pulse rounded" />
    </div>
  );

  return (
    <div>
      <div className="lg:w-[85%] w-[90%] mx-auto select-none">
        {loading ? (
          // Show loading placeholders if loading
          <LoadingSketch />
        ) : (
          <>
            <AnimatedSection>
              <div className="w-full mx-auto mb-8">
                <div className="flex items-center justify-center gap-4 w-full">
                  <div className="h-1 w-[30%] lg:w-[45%] bg-blue-500 "></div>
                  <h1 className="lg:text-3xl w-[30%] lg:w-[20%] text-center text-xl font-bold ">
                    ABOUT <span className="text-blue-500">ME</span>
                  </h1>
                  <div className="h-1 w-[30%] lg:w-[45%] bg-blue-500 "></div>
                </div>
                <div>
                  <p className="lg:text-sm text-[8px] text-center">
                    ALLOW ME TO INTRODUCE MYSELF
                  </p>
                </div>

                {/* Introduction */}
                <div className="flex flex-col items-center justify-center lg:gap-32 md:flex-row py-4">
                  <div className="flex mb-6 lg:w-80 w-60 lg:h-80 h-60 relative">
                    <div className="absolute inset-0 z-30 rounded-full lg:rounded-3xl border-4 border-l-blue-500 border-r-red-500 animate-pulse"></div>
                    <img
                      className="brightness-90   w-full object-top object-cover rounded-full sm:rounded-3xl "
                      src={myppimage}
                      alt="Profile"
                    />
                  </div>

                  <div className="w-full lg:w-1/2 ">
                    <h1 className="text-2xl font-extrabold mb-2">
                      Introduction
                      <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
                    </h1>
                    <p className={`mb-4 mt-4 text-justify ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      Welcome to my portfolio! I’m Bhishan Sah, a computer
                      engineer and web developer based in Nepal. With a passion
                      for technology and a strong foundation in software
                      development, I create dynamic and user-friendly web
                      applications. My approach combines technical expertise with
                      innovative design, ensuring that each project is not only
                      functional but also visually appealing. Explore my work to
                      see how I turn ideas into engaging digital experiences.
                    </p>
                  </div>
                </div>
              </div>
            </AnimatedSection>

            {/* Education Section */}
            <h2 className="text-2xl font-extrabold mb-4">
              Education
              <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
            </h2>
            {[
              {
                title: "10th Class",
                institution:
                  "Little Star Higher Secondary English Boarding School",
                location: "Lahan-7, Siraha, Nepal, pincode - 56500",
                major: "Science & English",
                passedYear: "2016 A.D",
                gradePercentage: "67%",
                gpa: "2.68",
                image: littleStarSchool,
              },
              {
                title: "Diploma in Mechanical Engineering",
                institution:
                  'Srm Global Institute of Technical Education "HSBTE BOARD"',
                location:
                  "Village-Bhurewala, Naraingarh, Ambala, Haryana, pincode - 134203",
                major: "Drawing & AutoCAD",
                passedYear: "2019 A.D",
                gradePercentage: "69%",
                gpa: "2.76",
                image: technicalEducationCollege,
                website: "https://hsbte.org.in/",
              },
              {
                title: "B-Tech in Computer Engineering",
                institution: "RK University",
                location: "Village-Tramba, Rajkot, Gujarat, pincode - 360020",
                major: "Software Engineering",
                passedYear: "2025 A.D",
                gradePercentage: "74% up to 6th sem only",
                gpa: "7.4",
                image: rkuniversity,
                website: "https://rku.ac.in/",
              },
            ].map((edu, index) => (
              <AnimatedSection key={index}>
                <div
                  className={`flex justify-between mb-6 shadow-md text-left border-b-2 lg:pb-4 rounded-lg ${
                    darkMode
                      ? "bg-gray-700/80"
                      : "bg-color-white border-blue-600"
                  }`}
                >
                  <div className="relative list-disc text-justify flex flex-col gap-4 mb-4 rounded-lg lg:text-md text-sm">
                    <h3 className="text-lg lg:w-fit w-[100%] font-semibold bg-purple-400/30 text-center lg:text-left px-8 rounded-tr-lg lg:rounded-tr-sm rounded-tl-lg py-2">
                      {edu.title}
                    </h3>
                    <h4 className="text-lg font-bold bg-blue-600/25 p-2 rounded-lg mx-4">
                      {edu.institution}
                    </h4>
                    <div className="px-8 lg:px-8">
                      <li>{edu.location}</li>
                      <li>
                        Major Subject - <strong>{edu.major}</strong>
                      </li>
                      <li>
                        Passed year - <strong>{edu.passedYear}</strong>
                      </li>
                      <li>
                        Grade Percentage -{" "}
                        <strong>{edu.gradePercentage}</strong>
                      </li>
                      <li>
                        Grade - <strong>{edu.gpa}</strong> GPA
                      </li>
                      {edu.website && (
                        <li>
                          Website URL -{" "}
                          <a className="text-blue-500" href={edu.website}>
                            {edu.website}
                          </a>
                        </li>
                      )}
                    </div>
                  </div>

                  <img
                    className="w-1/4 h-48 mt-2 hidden sm:block rounded-lg m-4"
                    src={edu.image}
                    alt={edu.title}
                  />
                </div>
              </AnimatedSection>
            ))}

            {/* Experience and Certificates Section */}
            <AnimatedSection>
              <div
                className={`lg:flex justify-between gap-8 shadow-md rounded-lg px-4 lg:px-8 py-3 ${
                  darkMode
                    ? "bg-gradient-to-r to-color-white/50 from-gray-700/70"
                    : "bg-gradient-to-r to-color-black/40 from-color-blue/15 "
                }`}
              >
                <div className="mb-8">
                  <h2 className="text-2xl font-extrabold mb-4">
                    EXPERIENCE
                    <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
                  </h2>
                  <ul
                    className={`list-disc text-justify flex flex-col gap-4 mb-4 shadow-sm rounded-lg p-6 lg:text-md text-[14px] ${
                      darkMode ? "bg-color-black/70 text-color-white/70 " : " bg-white/70 text-color-black/70 "
                    }`}
                  >
                    <li>
                      <strong>1 Year</strong> Experience in user account management
                      at Vianet Communication Pvt. Ltd.
                    </li>
                    <li>
                      <strong>2 Years</strong> of field experience with knowledge of
                      wiring, connection & layout designing.
                    </li>
                    <li>
                      <strong>8 Months</strong> of experience as supervisor at Shree
                      Krishna Distribution Janakpur.
                    </li>
                  </ul>
                </div>

                {/* Certificates Section */}
                <div className="">
                  <h2 className="text-2xl font-extrabold mb-2">
                    CERTIFICATES
                    <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
                  </h2>
                  <ul
                    className={`list-disc text-justify flex flex-col gap-4  shadow-sm rounded-lg p-6 lg:text-md text-[14px] ${
                      darkMode ? "bg-color-black/70 text-color-white/70 " : " bg-white/70 text-color-black/70 "
                    }`}
                  >
                    <li>
                      <strong>8 Months</strong> Experience certificate of mechanical
                      at Shree Krishna Distribution Mahendra Company.
                    </li>
                    <li>CISCO Networking</li>
                    <li>Java Language Features</li>
                    <li>Java Programming Fundamentals</li>
                    <li>Java SE 8 Features</li>
                  </ul>
                </div>
              </div>
            </AnimatedSection>

            {/* Last Section */}
            <AnimatedSection>
              <div
                className={`w-full my-6 flex flex-wrap justify-between shadow-lg rounded-lg px-4 lg:px-8 py-3 ${
                  darkMode
                    ? "bg-gradient-to-l to-color-white/50 from-gray-700/70"
                    : "bg-gradient-to-l to-color-black/40 from-color-blue/15 "
                }`}
              >
                {/* Things I Love Section */}
                <div>
                  <h1 className="text-2xl font-bold mb-2">
                    THINGS I LOVE
                    <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
                  </h1>
                  <div
                    className={`flex flex-wrap rounded-lg p-1 ${
                      darkMode ? "bg-color-black/70 text-color-white/70 " : " bg-white/70 text-color-black/70 "
                    }`}
                  >
                    {[
                      reading,
                      running,
                      cooking,
                      movies,
                      netflix,
                      coding,
                      traveling,
                    ].map((image, index) => (
                      <img
                        key={index}
                        className="w-8 h-8 m-2 object-cover"
                        src={image}
                        alt="Hobbies"
                      />
                    ))}
                  </div>
                </div>

                {/* Skills Section */}
                <div className="mt-4 w-full">
                  <h1 className="text-2xl font-bold mb-3 ">
                    LOOK WHAT I CAN DO
                    <div className={`w-24 h-[3px] rounded-full ${darkMode ? 'bg-color-white' : 'bg-color-black '}`}></div>
                  </h1>
                  <div
                    className={`lg:grid grid-cols-2 w-full p-3 flex flex-col gap-4 rounded-lg ${
                      darkMode ? "bg-color-black/70 text-color-white/70 " : " bg-white/70 text-color-black/70 "
                    }`}
                  >
                    {[
                      "Web Designing",
                      "UI / UX Designing",
                      "Familiar with React/Vite",
                      "Familiar with Laravel",
                      "App Development: Flutter & React Native",
                      "PhotoShop PSD Designing",
                      "Video Editing",
                      "API Integrity & Creating APIs",
                      "Full Stack Web Development MERN",
                    ].map((skill, index) => (
                      <div key={index} className="flex items-center ">
                        <span
                          className={`text-2xl mr-2 ${
                            darkMode ? "text-green-600" : "text-orange-700"
                          }`}
                        >
                          +
                        </span>
                        <p className="lg:text-lg text-md">{skill}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </AnimatedSection>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
