import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import ProfileImg1 from "../assets/img/homemainimg-1.png";
import ProfileImgsmall from "../assets/img/my_image4.webp";
import ProfileImg from "../assets/img/555.jpg";
import ProfileImg2 from "../assets/img/my_image1.webp";
import { Typewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";

// Lazy load components
const MyExpertise = lazy(() => import("../components/ExpertiesComponent"));
const MyCertificateHome = lazy(() => import("../components/MyCertificateHome"));
const Projects = lazy(() => import("../components/Projects"));

// Preload images function
const preloadImages = (imageArray) => {
  return Promise.all(
    imageArray.map((image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = resolve; // Resolve on error to avoid blocking
      });
    })
  );
};

const HomePage = ({ darkMode }) => {
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loading
  const { ref: leftRef, inView: leftInView } = useInView({ threshold: 0.1 });
  const { ref: mleftRef, inView: mleftInView } = useInView({ threshold: 0.1 });
  const { ref: rightRef, inView: rightInView } = useInView({ threshold: 0.1 });

  const certificateRef = useRef(null); // Ref for the certificate section

  // Preload images and components
  useEffect(() => {
    const loadContent = async () => {
      await preloadImages([ProfileImg, ProfileImg1]); // Preload imported images
      setLoading(false); // Set loading to false after images are loaded
    };

    loadContent();
  }, []);

  const handleDownloadCv = () => {
    try {
      setDownloading(true);
      const link = document.createElement("a");
      link.href = "/my-resume/Bhishan-sah_Resume_CV.pdf"; // Ensure this path is correct
      link.download = "Bhishan-sah_Resume_CV.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => setDownloading(false), 1000);
    } catch (error) {
      console.error("Error downloading CV:", error);
      setDownloading(false);
    }
  };

  useEffect(() => {
    document.title = "Bhishan Sah - Web Developer Portfolio";

    // Set meta description
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = "My portfolio home page with certificates";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <>
      <div className="lg:mt-6 lg:py-2 py-8 -mt-8 select-none overflow-hidden">
        {/* Loading Screen */}
        {loading ? (
          <div className="flex flex-col items-center justify-center w-full h-screen">
            {/* Loading placeholders */}
            {/* Mobile Image Section */}
            <div className="w-full block sm:hidden px-4 mb-8">
              <div className={`bg-gradient-to-r from-purple-600 to-white shadow-md shadow-black/25 rounded-lg mb-8 opacity-65`}>
                <div className="bg-gray-200 h-80 w-full rounded-lg animate-pulse"></div>
              </div>
            </div>

            {/* Hero Section */}
            <div className="flex flex-col lg:flex-row w-full lg:mb-0 mb-8 items-center justify-between">
              {/* Placeholder for Left Image */}
              <div className="lg:w-1/4 hidden sm:block">
                <div className="bg-gradient-to-br from-purple-300 to-blue-200 w-full h-64 rounded-lg animate-pulse shadow-lg"></div>
              </div>

              {/* Intro Section Details */}
              <div className="lg:mx-8 lg:w-2/4 mx-4 flex flex-col justify-center items-center">
                <div className="text-center mb-6">
                  <div className="bg-gradient-to-r from-indigo-300 to-purple-300 h-12 w-3/4 rounded-full animate-pulse mb-3"></div>
                  <div className="bg-gradient-to-r from-pink-200 to-red-200 h-8 w-1/2 rounded-full animate-pulse mb-4"></div>
                </div>

                <h1 className="flex w-3/4 justify-center gap-4 py-2 font-semibold mb-6">
                  <span className="bg-gradient-to-r from-green-200 to-blue-200 h-8 w-1/3 rounded-full animate-pulse mr-2"></span>
                  <span className="bg-gradient-to-r from-yellow-200 to-orange-200 h-8 w-1/3 rounded-full animate-pulse"></span>
                </h1>

                <div className="bg-gradient-to-r from-teal-200 to-cyan-200 h-6 w-5/6 rounded-full animate-pulse mb-6"></div>

                <div className="bg-gradient-to-r from-purple-400 to-pink-400 h-12 w-1/3 rounded-full animate-pulse shadow-md"></div>
              </div>

              {/* Placeholder for Right Image */}
              <div className="lg:w-1/4 hidden sm:block">
                <div className="bg-gradient-to-bl from-blue-300 to-purple-200 w-full h-64 rounded-lg animate-pulse shadow-lg"></div>
              </div>
            </div>

            {/* Expertise Section */}
            <div className="w-full mt-12 mb-6">
              <div className="animate-pulse bg-gray-200 h-20 rounded w-full mb-4"></div>
            </div>

            {/* Certificate Section */}
            <div className="mb-6" ref={certificateRef}>
              <div className="animate-pulse bg-gray-200 h-20 rounded w-full mb-4"></div>
            </div>

            {/* Projects Section */}
            <div className="mb-6">
              <h1 className={`text-center w-full lg:w-full text-white uppercase text-2xl py-1 font-bold bg-gray-400`}>
                <div className="animate-pulse bg-gray-200 h-6 rounded w-1/3 mb-2"></div>
              </h1>
              <div className="animate-pulse bg-gray-200 h-20 rounded w-full mb-4"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Mobile Image Section */}
            <motion.div
              className="w-full block sm:hidden px-4 "
              ref={mleftRef}
              initial={{ opacity: 0, x: -100 }}
              animate={mleftInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <div className={`relative group mb-8 ${darkMode ? "opacity-90" : ""}`}>
                <div className="relative">
                  <div className={`p-2 bg-gradient-to-br ${darkMode ? 'from-gray-900 to-gray-800' : 'from-white to-gray-50'} rounded-2xl shadow-xl`}>
                    <img
                      src={ProfileImg2}
                      alt="Profile"
                      className="object-cover h-80 object-top w-full rounded-xl ttransition-transform duration-300  hover:scale-105 shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Hero Section */}
            <div className="flex w-full lg:mb-0 mb-8">
              <motion.div
                className="lg:w-1/4 hidden rounded-full sm:block px-12 py-6 relative"
                ref={leftRef}
                initial={{ opacity: 0, x: -100 }}
                animate={leftInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
               <div className="relative  pb-[100%] p-16">
                  <div className={`absolute inset-0 -z-10 rounded-full ${darkMode ? 'bg-blue-500/20' : 'bg-blue-200/50'} blur-sm transform scale-110`}></div>
                  <div className={`absolute inset-0 -z-10 rounded-full ${darkMode ? 'bg-purple-500/20' : 'bg-purple-200/50'} blur-sm transform scale-105`}></div>
                  <img
                    src={ProfileImg1}
                    alt="Profile"
                    className={`absolute inset-0 object-cover object-top rounded-full w-full h-full border-4 ${darkMode ? 'border-gray-500 shadow-sm shadow-blue-500/20' : 'border-white shadow-sm shadow-blue-200/50'} transition-transform duration-300 hover:scale-105`}
                  />
                  </div>
              </motion.div>

              {/* Intro Section Details */}
              <motion.div
                className="lg:mx-8 lg:w-3/4 mx-4 lg:flex flex-col justify-center items-center"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <motion.div
                  className="lg:text-center"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  <motion.p
                    className={`md:text-5xl text-3xl font-extrabold font-Rubik lg:font-handwriting lg:uppercase tracking-[3px] ${
                      darkMode ? "text-white" : "text-blue-800"
                    }`}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                  >
                    Bhishan Prasad Sah
                  </motion.p>
                  <motion.span
                    className="text-[12px] text-left w-full font-semibold tracking-[3px]"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                  >
                    Computer Engineer
                  </motion.span>
                </motion.div>

                <motion.h1
                  className="flex lg:w-[25%] gap-4 py-2 font-semibold"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8, duration: 0.5 }}
                >
                  <span className="text-red-600 text-xl">I'M</span>
                  <span className="text-xl tracking-[1px]">
                    <Typewriter
                      words={[
                        "Web Developer",
                        "Photographer",
                        "UI/UX Designer",
                        "Videographer",
                      ]}
                      loop={true}
                      cursor
                      cursorStyle="_"
                      typeSpeed={70}
                      deleteSpeed={100}
                      delaySpeed={1000}
                    />
                  </span>
                </motion.h1>

                <motion.p
                  className={`text-lg font-light text-justify lg:text-center lg:w-3/4 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  I'm a web developer who is passionate about building beautiful and
                  functional websites and learning new technologies or ways of doing
                  new things.
                </motion.p>

                <motion.button
                  onClick={handleDownloadCv}
                  className={`mt-4 px-4 py-2 flex gap-2 items-center bg-blue-800 hover:bg-color-blue text-white rounded font-bold transition-transform duration-300 ${
                    downloading ? "cursor-not-allowed" : ""
                  }`}
                  disabled={downloading}
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 1.2, duration: 0.5 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <i className={`fa-solid fa-cloud-arrow-down`}></i>
                  {downloading ? "Downloading..." : "Download CV"}
                </motion.button>
              </motion.div>

              <motion.div
                className="lg:w-1/4 h-auto hidden rounded-full sm:block  relative"
                ref={rightRef}
                initial={{ opacity: 0, x: 100 }}
                animate={rightInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                transition={{ duration: 0.5 }}
              >
                  <div className="relative  h-full w-full">
                 <div className="p-2">
                  <img
                    src={ProfileImgsmall}
                    alt="Profile"
                    className={` relative object-cover border-4 border-blue-600/25  rounded-tl-[120px] rounded-br-[150px] rounded-tr-[250px] rounded-bl-[180px]   transition-transform duration-300 `}
                  />
                  </div>
                  
                </div>
              </motion.div>
            </div>

            <div className="w-full mt-16">
              {/* Expertise Section */}
              <Suspense fallback={<div className="animate-pulse bg-gray-200 h-20 rounded w-full mb-4"></div>}>
                <MyExpertise darkMode={darkMode} />
              </Suspense>
            </div>

            <div className="mb-6" ref={certificateRef}>
              {/* Certificate Section */}
              <Suspense fallback={<div className="animate-pulse  h-20 rounded w-full mb-4"></div>}>
                <MyCertificateHome darkMode={darkMode} />
              </Suspense>
            </div>

            <div className="mb-12 relative">
              {/* Projects Section */}
              <Suspense fallback={<div className="animate-pulse bg-gray-200 h-20 rounded w-full mb-4"></div>}>
                <div className="relative">
                  <h1 className={`text-center w-full lg:w-full text-3xl font-extrabold py-3 
                    ${darkMode ? 
                      "bg-gradient-to-r from-gray-200 to-gray-500 text-transparent bg-clip-text" 
                      : "bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text"
                    }
                    before:content-[''] before:absolute before:left-1/2 before:-translate-x-1/2 
                    before:bottom-0 before:w-24 before:h-1 
                    ${darkMode ? "before:bg-gray-400" : "before:bg-blue-600"}
                    before:rounded-full`}
                  >
                    Featured Projects
                  </h1>
                  <div className={`absolute -z-10 top-0 left-1/2 -translate-x-1/2 w-48 h-48 
                    ${darkMode ? "bg-gray-700" : "bg-blue-200"} 
                    rounded-full blur-[80px] opacity-50`}>
                  </div>
                </div>
                <Projects darkMode={darkMode} />
              </Suspense>
            </div>
          </>
        )}
      </div>
      <Footer darkMode={darkMode}  />
    </>
  );
};

export default HomePage;
