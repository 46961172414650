import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../utils/api';

const PhotoGallery = ({ darkMode }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryMedia, setGalleryMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMediaData();
  }, []);

  const fetchMediaData = async () => {
    try {
      const response = await api.get('/media/all');
      setGalleryMedia(response.data);
    } catch (error) {
      console.error('Error fetching media:', error);
    } finally {
      setLoading(false);
    }
  };

  const openMediaView = (index) => {
    setSelectedMedia(galleryMedia[index]);
    setCurrentIndex(index);
  };

  const closeMediaView = () => {
    setSelectedMedia(null);
  };

  const goToPrevious = () => {
    const newIndex = (currentIndex - 1 + galleryMedia.length) % galleryMedia.length;
    setSelectedMedia(galleryMedia[newIndex]);
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = (currentIndex + 1) % galleryMedia.length;
    setSelectedMedia(galleryMedia[newIndex]);
    setCurrentIndex(newIndex);
  };

  const checkVideoSupport = (videoElement) => {
    if (videoElement) {
      return videoElement.canPlayType && videoElement.canPlayType('video/mp4').replace(/no/, '');
    }
    return false;
  };

  const getVideoEmbedUrl = (url) => {
    // Handle YouTube URLs
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.includes('youtube.com') 
        ? url.split('v=')[1]
        : url.split('youtu.be/')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    
    // Handle Vimeo URLs
    if (url.includes('vimeo.com')) {
      const videoId = url.split('vimeo.com/')[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }
    
    // Handle Google Drive URLs
    if (url.includes('drive.google.com')) {
      const fileId = url.match(/[-\w]{25,}/);
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
    
    // Return original URL for direct video files
    return url;
  };

  const renderVideo = (url, isFullView = false) => {
    // For direct video URLs (like those from your upload)
    if (url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg')) {
      return (
        <video
          src={url}
          controls
          controlsList="nodownload"
          className={isFullView 
            ? "lg:h-[80vh] h-screen bg-gray-300 w-full object-contain rounded-lg" 
            : "w-full h-24 lg:h-64 object-cover"
          }
        >
          <source src={url} type="video/mp4" />
          <source src={url} type="video/webm" />
          <source src={url} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      );
    }

    // For embedded videos (YouTube, Drive, etc.)
    const embedUrl = getVideoEmbedUrl(url);
    return (
      <iframe
        src={embedUrl}
        className={isFullView 
          ? "lg:h-[80vh] h-44 w-full my-auto border-2 border-gray-300 outline-none rounded-lg" 
          : "w-full h-24 lg:h-64"
        }
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
      />
    );
  };

  if (loading) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'} flex items-center justify-center`}>
        <p className="text-xl">Loading media...</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className="lg:mx-12 mx-3">
        <h2 className="text-4xl font-bold text-center mb-6">Media Gallery</h2>
        
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 pb-8">
          {galleryMedia.map((media, index) => (
            <div 
              key={media._id}
              className="group relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
              onClick={() => openMediaView(index)}
            >
              {media.type === 'image' ? (
                <img
                  src={media.url}
                  alt={media.title}
                  className="w-full lg:max-h-60 max-h-32 object-top transform group-hover:scale-105 transition-transform duration-300"
                />
              ) : (
                <div className="relative w-full lg:max-h-60 max-h-32">
                  {renderVideo(media.url)}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M8 5v14l11-7z"/>
                    </svg>
                  </div>
                </div>
              )}
              <div className="absolute inset-0 bg-black text-white bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end p-4">
                <h3 className="text-lg font-semibold hidden sm:block">{media.title}</h3>
                <p className="text-sm hidden sm:block">{media.description}</p>
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="flex items-center justify-center min-h-screen">
            <div className="loader"></div>
          </div>
        )}

        <AnimatePresence>
          {selectedMedia && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black flex items-center justify-center z-50"
              onClick={closeMediaView}
            >
              <div className="relative lg:w-[85%] w-full flex flex-col items-center justify-center" onClick={(e) => e.stopPropagation()}>
                {selectedMedia.type === 'image' ? (
                  <img
                    src={selectedMedia.url}
                    alt={selectedMedia.title}
                    className="h-[80vh] w-full object-contain"
                  />
                ) : (
                  <div className="relative w-[85%] mx-auto h-full">
                    {renderVideo(selectedMedia.url, true)}
                  </div>
                )}
                <div className="mt-4 text-white text-center">
                  <h3 className="text-2xl font-bold">{selectedMedia.title}</h3>
                  <p className="text-lg">{selectedMedia.description}</p>
                </div>
                
                <button
                  className="absolute left-4 py-3 transform -translate-y-1/2 px-3 flex items-center text-white text-4xl"
                  onClick={goToPrevious}
                >
                  &#8249;
                </button>
                <button
                  className="absolute right-4 py-3 transform -translate-y-1/2 px-3 flex items-center text-white text-4xl"
                  onClick={goToNext}
                >
                  &#8250;
                </button>
                
              </div>
              <button
                  className="absolute top-4 right-4 bg-gray-400 lg:py-1 px-2 py-0 rounded-md hover:bg-gray-500 text-red-900 text-3xl"
                  onClick={closeMediaView}
                >
                  <p className='lg:-mt-1 mt-0'>&times;</p>
                </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PhotoGallery;